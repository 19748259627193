<template>
  <div>
    <Breadcrumb :model="items"/>
    <div class="page p-mt-3 p-p-3">
      <div class="p-col-10">
        <div class="p-mb-2" style="font-size: 20px;font-weight: bold">{{data.title}}</div>
        <div class="p-mb-2">问题发起人：{{data.companiesName}}</div>
        <div class="p-mb-2">{{data.submitTime}}</div>
        <div class="p-mb-2" v-html="data.content">

        </div>
        <div class="p-mb-2 p-col-6">
          <Timeline :value="progress" align="top" layout="horizontal">
            <template #content="slotProps">
              {{ slotProps.item }}
            </template>
          </Timeline>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "BusinessContent",
  data() {
    return {
      data:{},
      items: [
        {label: '业务需求管理', to: '/'},
        {label: '受理状态查询', to: '/businessRequirements/StatusQuery'},
        {label: '详情'}
      ],
      progress: []
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    getData(){

      let list= ['已受理', '研讨中', '结果', '完',]
      let id=this.$route.query.id
      this.$http.get('/demands/getDemands?id='+id).then((res)=>{
        this.data=res.data
        console.log(res.data)
        for (let i in list){
          if(i<res.data.state){
            this.progress.push(list[i])
          }
        }
      })
  }
}
}
</script>

<style scoped>

</style>